/* General navbar styles */
.nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    background-color: #333; /* Adjust as needed */
}

.logoClass {
    height: 100px; /* Adjust as needed */
}

.bars {
    display: none; /* Initially hide the hamburger icon */
}

/* Navbar menu styles for larger screens */
.nav-menu {
    display: flex;
    flex-direction: row;
    list-style: none;
}

.nav-menu a {
    padding: 1rem;
    text-decoration: none;
    color: white; /* Adjust as needed */
}

.desktop-link {
    color: white !important; /* Ensure Roster and Schedule are white on desktop */
}

.nav-menu a:hover {
    background-color: #555; /* Adjust as needed */
}

/* Dropdown menu styles for smaller screens */
.nav-menu.dropdown {
    display: none; /* Initially hide the dropdown menu */
    flex-direction: column;
    position: absolute;
    top: 75px; /* Adjust based on your navbar height */
    right: 0;
    background-color: white; /* or any other background color */
    width: 48%; /* Full width */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1); /* Optional for a shadow effect */
    z-index: 1000; /* Ensure it appears above other elements */
}

.nav-menu.dropdown.active {
    display: flex;
}

.nav-menu.dropdown a {
    padding: 1rem;
    text-decoration: none;
    color: black; /* Ensure text is black in dropdown menu */
}

.mobile-link {
    color: black !important; /* Ensure Roster and Schedule are black on mobile */
}

.nav-menu.dropdown a:hover {
    background-color: #ddd; /* Adjust as needed */
}

.social-media-icons {
    display: flex;
    flex-direction: column; /* Stack items vertically */
}

.social-media-icons a {
    margin: 3px; /* Add vertical spacing between icons */
    text-decoration: none; /* Remove underline from links */
}

/* Optional: Adjust icon size and color */
.social-media-icon {
    color: black; /* Set the color of the icons to black */
    font-size: 150%; /* Increase the size by 50% */
}

/* Media queries for responsive design */
@media (max-width: 768px) {
    .nav-menu {
        display: none; /* Hide the regular navbar menu */
    }
    .bars {
        display: block; /* Show the hamburger icon */
    }
    /* Example media query for smaller screens */

    .social-media-icons {
        top: 10%; /* Adjust vertical alignment for smaller screens if needed */
    }


}
